import { useEffect, useState } from 'react';
import './DisplayPanel.css';

export default function DisplayPanel({visible, children}){
	const [show, setShow] = useState(false);

	useEffect(()=>{
		setShow(visible)
	} ,[visible])

	if(show)
	return (<div>
		{children}
	</div>)
}
