import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; 
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LEVEL, Logger } from '@kcram-solutions/logger';

Logger.level = LEVEL.ERROR;

const root = ReactDOM.createRoot(document.getElementById('root'));
// iniciamos el router
root.render(
  <BrowserRouter> 
    <App />
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
