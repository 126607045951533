import { useState } from 'react';
import './ElementoListaSimbolos.css';

export default function ElementoListaSimbolos({id, callback, code, desc, img}, content){
	function clickEvent(){
		callback(id);
	}
	return (
	<div className='listItem_Simbolos' onClick={clickEvent}>
		<div className='listItem_Simbolos_code'>
			<span>{code}</span>
			<br/>
			<span>{desc}</span>
		</div>
		<div className='listItem_Simbolos_code'>
			<img src={img} alt={code} />
		</div>
	</div>
	)
}
