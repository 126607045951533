import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import literals from '../../constants/literals';
import paths from '../../constants/paths';
import globals from '../../constants/globals';
import storageKeys from '../../constants/storageKeys'; 
import { almacenar, recuperar } from '../../helpers/local_storage';
import { checkValidity } from '../../helpers/tokenUtils';
import { Login } from '../../requests/login';
import './Login.css';   

export default function LoginForm({onLogin}) {
  const placeholder_email = literals.login.user; 
  const placeholder_password =literals.login.pass;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('Datos erroneos');
  const [checkbox, setCheckbox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate()

  const div_esconder_error_login = "div_esconder_error_login";
  const div_mostrar_error_login = "div_mostrar_error_login";
  const [div_error_login, setDivErrorLogin] = useState(div_esconder_error_login);

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
    setDivErrorLogin(div_esconder_error_login)
  }

  function handlePasswordChange(e) {
    const { name, value } = e.target
    setPassword(value)
    setDivErrorLogin(div_esconder_error_login)
  }
 
  function handleCheckbox(e) {
    const { name, value } = e.target 
    setCheckbox(!checkbox) 
    setIsChecked(!isChecked) 
  }

  function handleButtonSubmit(e) {
    e.preventDefault()  
    Login.login(email, password).then (
      (data) => {
        if(data && data.success){  
          almacenar(globals.recuerdame.recordar, checkbox, true);  
          almacenar(storageKeys.user.token, data.token);
          almacenar(storageKeys.user.data, data);
          almacenar(storageKeys.user.login, true, true); 
          navigate(paths.generador);
          if(onLogin){
            onLogin();
          }
        } else { 
          data.message && setErrorMessage(data.message);
          setDivErrorLogin(div_mostrar_error_login) 
          setEmail('')
          setPassword('')
        } 
      }
    )
  }
  
useEffect(() => {
    // comprobamos si hay token para evitar iniciar sesión
    const token = recuperar(storageKeys.user.token);
    const recordar = recuperar(globals.recuerdame.recordar);
    const valores = recuperar(storageKeys.user.data); 
    // si es valido navegamos hacia la siguiente pantalla
    if(token){
      navigate(paths.generador);
    }
    if(recordar) { 
      setEmail(valores.user.email)
      setPassword(valores.user.password)
      setCheckbox(recordar)
      setIsChecked(true)
    }
     
},[])
  
  return (
    <div>
      <div className='inicio_formulario_login'>
        <h1 className='titulo_login'>Herramienta generadora de etiquetas</h1>
        <p className='p_login'>Inicia sesión o regístrate para acceder a la herramienta generadora de etiquetas para Residuos peligrosos.</p>
        <img className="imagen_login" title='Iniciar sesión' alt="" src="/img/login128.png"/>
        <h3 className='sub_login'>Iniciar sesión</h3>
        <form onSubmit={handleButtonSubmit}> 
          <label className="display_inputs_login" for> 
            <input
              className='inputs_login'
              type="email"
              name="email" 
              placeholder={placeholder_email}
              required
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <label className="display_inputs_login" for> 
            <input
              className='inputs_login'
              type="password"
              name="password" 
              placeholder={placeholder_password}
              required
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
          <p className='olvidar_contra_login'>
            <Link to="/reset" >¿Has olvidado la contraseña?</Link>
            </p>
          <label className='checkbox_login' for> 
            <input 
              type="checkbox" 
              className='input_checkbox_login' 
              id="recuerdame" 
              name="recuerdame" 
              checked={isChecked}
              value="recuerdame" 
              onChange={handleCheckbox}/> 
            <label className='label_checkbox_login' for="recuerdame">Recuérdame</label>
          </label>
          <div className= {div_error_login}> 
            <p className="parrafo_error_login">{errorMessage}</p> 
          </div>
          <button type="submit" className='btn_form_login'>Acceder</button>
        </form>
        <Link to={paths.registro} className='crear_cuenta_login'>
          ¿No tienes cuenta? Crea una aquí
        </Link>
      </div>
    </div> 
  )
}

