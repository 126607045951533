import './ElementoMenuPrincipal.css';
import {Row, Container, Col} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { almacenar, recuperar } from '../../helpers/local_storage'; 
import storageKeys from '../../constants/storageKeys';
import paths from '../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { logoutFromLocal } from '../../helpers/logout';

export default function ElementoMenuPrincipal({show}){

	const [esconder_logo, setEsconder] = useState(show ?"esconder": ""); 
	const navigate = useNavigate()

	function logout() {
		setEsconder("esconder")
		logoutFromLocal();
    almacenar(storageKeys.user.login, false);
		navigate(paths.login);
	}

	useEffect(() => {
		const login = recuperar(storageKeys.user.login);
    	if(login) {
      		setEsconder("") 
    	} else {
			setEsconder("esconder")
		}
	  }, [show]);

	return (
		<div className='div_menu_principal'>
			<Container noGutters>
				<Row>
					<Col lg={3} md={3} sm={3} xs={12} className='col_l_m_p'>
						<div className='div_logo_m_p'>
							<a href='https://serviciosecologicos.com'><img className='logo_menu_principal' title='Logo' alt="" src="/img/logo-para-el-menu.svg"/></a>
						</div>
					</Col>
					<Col lg={6} md={6} sm={6} xs={12} className='col_p_m_p'>
						<div className='div_p_m_principal'>
							<p className='parrafo_menu_principal'>Generador etiquetas</p> 
							<hr/> 
						</div>
					</Col>
					<Col lg={3} md={3} sm={3} xs={12} className='col_lo_m_p'>
						<div className={'div_logout_m_p ' + esconder_logo} onClick={logout}>
							<span className='span_logo_salir'>
								<img className='logout_menu_principal' title='Logo' alt="" src="/img/logout.svg"/><span className='salir_m_p'>Salir</span>
							</span>
						</div>
					</Col>
				</Row>
			</Container>
			
			
		</div>
	)
}
