import './App.css';  
import Login from '../Login/Login';
import Registro from '../Registro/Registro';
import MisDatos from '../MisDatos/MisDatos';
import VistaGenerador from '../VistaGenerador/VistaGenerador';
import Vista from '../Vista/Vista';
import { Route, Routes } from 'react-router-dom';
import ElementoMenuPrincipal from '../ElementoMenuPrincipal/ElementoMenuPrincipal';
import ElementoFooter from '../ElementoFooter/ElementoFooter';
import { useState } from 'react';
import BannerInfo from '../BannerInfo/BannerInfo';
import PassRestore from '../PassRestore/PassRestore';
import PlayerBanner from '../PlayerBanner/PlayerBanner';
import ResetearPassForm from '../ResetearPass/ResetearPass';


function App() {
  const [showLogout, setShowLogout] = useState(false);

  function updateView(){
    setShowLogout(!showLogout);
  }

  return (
    <div>
      <div>
        <ElementoMenuPrincipal show={showLogout} />
      </div>
      <div>
        <PlayerBanner />
        <BannerInfo />
      </div>
      <div className="wrapper-app">
        <Routes>
          <Route path='/' element={<Login onLogin={updateView} />} />
          <Route path='/registro' element={<Registro />} />
          <Route path='/generador/:id?' element={<VistaGenerador />} />
          <Route path='/datos' element={<MisDatos />} />
          <Route path='/vista' element={<Vista />} />
          <Route path='/reset' element={<PassRestore />} />
          <Route path='/users/reset-password/:token/:sha' element={<ResetearPassForm />} />
        </Routes>
      </div>
      <div>
        <ElementoFooter />
      </div>
    </div>
  );
}

export default App;
