import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import storageKeys from '../../constants/storageKeys';
import { createImageURLFromSimbolo } from '../../helpers/image';
import { almacenar, recuperar } from '../../helpers/local_storage';
import { Simbolos } from '../../requests/simbolos';
import ElementoListaSimbolos from '../ElementoListaSimbolos/ElementoListaSimbolos';
import './ListaSimbolos.css';

export default function ListaSimbolos({ index, show, onSelect, onCancel }, content) {
	const [data, setData] = useState(); // para los datos
	const [isLoading, setLoading] = useState(true); // para la carga
	const [hide, setHide] = useState(show ?? true);


	function selectSimbolo(id) {
		const	seleciones = data[id];
		if(onSelect){
			onSelect(seleciones);
		}
		updateHide();
	}
	// hace que se ejecute una sola vez cuando se monta el componente
	useEffect(() => {
		setData(recuperar(storageKeys.simbolos.buffer));
		const lastMod = recuperar(storageKeys.simbolos.last);
		// si la última actualización fue hace más de tres minutos
		// o no hay datos recuperamos los datos
		if ((new Date().getTime - lastMod) > (3 * 60000) || !data) {
			Simbolos.list().then(simbolos => {
				setData(simbolos); // asignamos los datos a data
				setLoading(false); // cambiamos el estado de carga
				// guardamos los datos en el el localstorage
				almacenar(storageKeys.simbolos.buffer, simbolos);
				// TTL
				almacenar(storageKeys.simbolos.last, new Date().getTime());
			})
		} else if (data != null) {
			// si recuperamos los datos del localstorage y no estan expirados
			setLoading(false)
		}
	}, [])

	useEffect( () => {
		setHide(show);
	} ,[show]);
 
	function cancel(){
		if(onCancel){
			onCancel();
		}
		updateHide();
	}

	function updateHide(){
		setHide(!hide);
	}

	// mostramos un spinner mientras se recuperan los datos
	if (show) {
		// cuando esten de vuelta mostramos la lista
		return (
			<Modal show={hide} centered  onHide={cancel}>
				<Modal.Body>
					<div className='listSimbolos'>
						{data.map((elemento, i) => {
							return (<ElementoListaSimbolos id={i}
								img={createImageURLFromSimbolo(elemento)}
								callback={selectSimbolo}
								code={elemento.codigo}
								desc={elemento.desc}
							/>)
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={cancel}>Cancelar</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}
