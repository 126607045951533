/**
 * Convierte un objeto en un array
 * @param {object} obj objeto para la conversión
 * @returns {Array<any>} el array
 */
export function obj2Array(obj){
	const keys = Object.keys(obj);
	const out = new Array();
	for (const key of keys) {
		const value = obj[key];
		if(value){
			out.push(value);
		}
	}
	return out;
}

/**
 * Busca un valor de un ojeto en el array y devuelve el objeto entero
 * @param {Array} arr El array en el que buscar
 * @param {any} key la clave del objeto
 * @param {any} val el valor a bucar
 */
export function searchInArray(arr, key, val){
	if(arr.length === 0) return null;
	const current = JSON.parse(JSON.stringify(arr));
	const last = current.pop();
	if(last[key] == val) return last
	else
	return searchInArray(current, key, val);
}
/**
 * Genera un array con el valor de las claves de un array de objetos
 * @param {Array<object>} obj array de objetos
 * @param {string} key clave del objeto a extraer
 */
export function getValArrOfObj(obj, key){
	const out = new Array();
	obj.forEach((item => {
		if(item[key]){
			out.push(item[key]);
		}
	}));
	return out;
}