import './BannerInfo.css';

export default function BannerInfo(props, content) {

	return (
		<div className='banner_info'>
			<div className="row" Style="">
				<div className="col col-xs-12  col-sm-12" Style="">
					<div className="o-block b-contact" Style="">
						<h3><i className="fa fa-info"></i> Información</h3>
						<p>Póngase en contacto con nosotros y coméntenos sus necesidades. Responderemos a sus dudas, comentarios y/o sugerencias lo antes posible.</p>
						<div className="b-contact__button">
							<a href='https://serviciosecologicos.com/es/contacto'><button id="contact-button" >Contactar</button></a>
						</div> 
						<div className="b-contact__phone">
							<p>O por teléfono</p>
							<div className="b-contact__phone--phone">
								948 309 033
							</div>
						</div>
					</div>
				</div>
			</div> 
		</div>
	)
}
