import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 
import api from "../constants/api";
import storageKeys from "../constants/storageKeys";
import { recuperar } from "../helpers/local_storage";

export class MisDatos {
    static async update(empresa, nima, email, direccion, telefono, n_password, c_password, logo) {
        const url = urlJoin(api.host, api.base, api.users); 
        const body = {empresa, eni:nima, email, direccion, telefono, logotipo: logo}
        if(n_password && c_password) {
            body.password = n_password
            body.password2 = c_password
        }
        try{ 
            const response = await axios.patch(url, body, {headers: {"User-Agent": api.userAgent, "Authorization": "Bearer " + recuperar(storageKeys.user.token)}});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
             
        } catch(error){
            return false;
        } 
    }
}