export default {
	user: {
		token: "token", // dG9rZW4K
		data: "user.data", // dXNlci5kYXRhCg
		login: "user.login",
		loginDate: "last.login"
	},
	simbolos: {
		selecion: 'simbolos.selecion', //c2ltYm9sb3Muc2VsZWNpb24K
		buffer: 'simbolos.buffer',
		last: 'simbolos.lastModification' // c2ltYm9sb3MubGFzdE1vZGlmaWNhdGlvbgo=
	},
	residuos: {
		data: 'residuos.data' // cmVzaWR1b3MuZGF0YQo=
	},
	etiquetas:{
		selected: 'etiqueta.selected'
	}
}