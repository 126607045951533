
/**
 * Genera la URL en Base64 de la imagen de un simbolo
 * @param {Simbolo} simbolo el objeto simbolo
 * @returns {string} base64 URL
 */
export function createImageURLFromSimbolo(simbolo){
 if(!simbolo || !simbolo.imagen || !simbolo.imagen[0] 
		|| !simbolo.imagen_base64) 
	return false;

	const data = simbolo.imagen[0].mimetype;
	const base = simbolo.imagen_base64[0];

	const result = `data:${data};base64,${base}`;
	return result;
}