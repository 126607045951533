import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Etiquetas } from '../../requests/etiquetas';
import * as keys from '../../constants/storageKeys';
import './ElementoLista.css';
import { formatNumber, Logger } from '@kcram-solutions/logger';
import { getCodigoLer } from '../../helpers/codigos';

export default function ElementoLista({ cod_log, residuo, id, onChange, detalles, item }) {
  const [hide, setHide] = useState('');
  const [imagen, setImagen] = useState('');
  const [ler, setLerr] = useState('');
  const [fecha, setFecha] = useState('');
  const navigate = useNavigate();
  const logger = new Logger('elemento-lista');

  function editarGenerador(){
    localStorage.setItem(keys.default.etiquetas.selected, JSON.stringify(item));
    navigate("/generador/"+id);
  }
  function donwloadPDF() {
    const a = document.createElement('a');
    Etiquetas.getPDF("/"+id).then(({ pdf }) => {
      a.target = '_blank';
      a.href = pdf;
      a.download = residuo + '.pdf'
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
  }

  function remove() {
    Etiquetas.delete(id + "", residuo).then((d) => {
      console.log(d);
      setHide('hide');
      if (onChange) {
        onChange();
      }
    })
  }
  useEffect(() => {
    Etiquetas.getImage("/"+id).then(({ imagen }) => {
      setImagen(imagen);
    });

    if(item){
      const date = new Date(item.created);
      logger.info("Fecha",date);
      
      setFecha(`${formatNumber(date.getDate() +1,1)}/${formatNumber(date.getMonth()+1, 1)}/${date.getFullYear()}`)
    }
    if(item && item.observaciones){
      logger.info("item", item)
      try{
        const json = JSON.parse(item.observaciones);
        logger.info('Data extra', json);
        const codLer = getCodigoLer(json.codigo_ler.desc);
        logger.info('Codigo ler', codLer);
        if(codLer){
          setLerr(codLer)
        }
      }catch(err){}
    }
  }, [])
  if (imagen) {

    return (
      <div className={hide}>
        <div className='row align-items-end'>
          <div className='col-3 p-2'>
            <img className="imagen_etiquetas" title='Etiqueta' alt="" src={imagen} />
          </div>
          <div className='col-6 kcs-datos'>
            <p className='parrafo_c_ler'>Código LER:<span>{ler}</span></p>
            <p className='parrafo_detalles'>{detalles}</p>
            <p className='parrafo_residuo'>Nombre residuo:<span>{residuo}</span></p>
            <div className='fecha font-italic small'>{fecha}</div>
          </div>
          <div className='col-3'>
            
            <div className="editar_etiquetas iconos-btn" title='Duplicar' alt="" onClick={editarGenerador} >
            <i className="fa-regular fa-clone"></i>
            </div>
            <img className="flecha_etiquetas iconos-btn" title='Descargar' alt="" src="/img/flecha128.png" onClick={donwloadPDF} />
            <img className="cerrar_etiquetas iconos-btn" title='Borrar' onClick={remove} alt="" src="/img/cerrar128.png" />
          </div>
        </div>
        <hr />
      </div>
    )
  }else{
    return (
      <div className={hide}>
        <div className='row align-items-end'>
          <div className='col-3 p-2'>
            <img className="imagen_etiquetas" title='Etiqueta' alt=""  />
          </div>
          <div className='col-6'>
            <p className='parrafo_c_ler'>Código LER:<span>{cod_log}</span></p>
            <p className='parrafo_detalles'>{detalles}</p>
            <p className='parrafo_residuo'>Nombre residuo:<span>{residuo}</span></p>
            <div className='fecha font-italic small'>{fecha}</div>
          </div>
          <div className='col-3'>
            <img className="flecha_etiquetas" title='Etiqueta' alt="" src="/img/flecha128.png" onClick={donwloadPDF} />
            <img className="cerrar_etiquetas" title='Etiqueta' onClick={remove} alt="" src="/img/cerrar128.png" />
          </div>
        </div>
        <hr />
      </div>
    )
  }
}

