import React, { useEffect, useState } from 'react';
import './Generador.scss';
import Menu from '../Menu/Menu';
import { almacenar, recuperar } from '../../helpers/local_storage';
import { Residuos } from '../../requests/residuos';
import storageKeys from '../../constants/storageKeys';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ListaSimbolos from '../ListaSimbolos/ListaSimbolos';
import { createImageURLFromSimbolo } from '../../helpers/image'
import { genLandscape, genPortrait } from '../../helpers/pdf';
import { getValArrOfObj, obj2Array } from '../../helpers/objects';
import { createSelectForLer, getCodigoLer } from '../../helpers/codigos';

import { Logger } from '@kcram-solutions/logger';
import ListaCodigos from '../ListaCodigos/ListaCodigos';
import { RESIDUOS_PELIGROSOS } from '../../constants/helpers';
import PeligrosoSelector from '../PeligrosoSelector/PeligrosoSelector';

const logger = new Logger('Generador');

export default function GeneradorForm() {

  const navigate = useNavigate();

  const [empresa, setEmpresa] = useState('');
  const [nima, setNima] = useState('');
  const [email, setEmail] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [n_residuo, setN_Residuo] = useState('');
  const [codigo_ler, setCodigo_Ler] = useState('');
  const [codigoList, setCodigoList] = useState('');
  const [fecha_envasado, setFecha_Envasado] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [loading, setLoading] = useState(true);
  const [esProductoPeligroso, setEsProductoPeligroso] = useState(null);

  const [lista1, setLista1] = useState(false);
  const [lista2, setLista2] = useState(false);
  const [lista3, setLista3] = useState(false);

  const [esconder1, setEsconder1] = useState("esconder");
  const [mostrar1, setMostrar1] = useState("mostrar")
  const [esconder2, setEsconder2] = useState("esconder");
  const [mostrar2, setMostrar2] = useState("mostrar")
  const [esconder3, setEsconder3] = useState("esconder");
  const [mostrar3, setMostrar3] = useState("mostrar");

  const [residuosArrObj, setResiduosArrObj] = useState("");

  const [url_image1, setUrlImage1] = useState('');
  const [url_image2, setUrlImage2] = useState('');
  const [url_image3, setUrlImage3] = useState('');

  const [urls, setUrls] = useState({ img1: null, img2: null, img3: null })

  const [formatoPDF, setFormatoPDF] = useState('');
  const [formato_seleccionado_a4, setFormatoSeleccionado_a4] = useState("")
  const [formato_seleccionado_a5, setFormatoSeleccionado_a5] = useState("")

  function handleSwitchChange(e) {
    setEsProductoPeligroso(!esProductoPeligroso);
    if (esProductoPeligroso) {
      handleFormatoPDFClick("a4", e)
    } else {
      setFormatoPDF("");
    }
    console.log(formatoPDF);
  }

  function handleEmpresaChange(e) {
    const { value } = e.target
    setEmpresa(value)
  }

  function handleNimaChange(e) {
    const { name, value } = e.target
    setNima(value)
  }

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handleDireccionChange(e) {
    const { name, value } = e.target
    setDireccion(value)
  }

  function handleTelefonoChange(e) {
    const { name, value } = e.target
    setTelefono(value);
  }

  function handleN_Residuo(e) {
    const { name, value } = e.target
    setN_Residuo(value)
  }


  function handleCodigo_Ler(e) {
    const { name, value } = e.target
    setCodigo_Ler(value)
  }

  function handleFecha_Envasado(e) {
    const { name, value } = e.target
    setFecha_Envasado(value)
  }

  function handleObservaciones(e) {
    const { name, value } = e.target
    setObservaciones(value)
  }

  function quitarEtiqueta(numero) {
    var div_con_etiquetas = document.getElementsByClassName("div_con_etiquetas")[numero];
    alert(numero);
  }

  function showElement1() {
    setLista1(true);
  }

  function showElement2() {
    setLista2(true);
  }

  function showElement3() {
    setLista3(true);
  }

  function select1(data) {
    setLista1(false);
    const url = createImageURLFromSimbolo(data)
    setEsconder1("mostrar")
    setMostrar1("esconder")
    setUrlImage1(url)
    setUrls({ img1: data, img2: urls.img2, img3: urls.img3 })
  }

  function select2(data) {
    setLista2(false);
    const url = createImageURLFromSimbolo(data)
    setEsconder2("mostrar")
    setMostrar2("esconder")
    setUrlImage2(url)
    setUrls({ img1: urls.img1, img2: data, img3: urls.img3 })
  }

  function select3(data) {
    setLista3(false);
    const url = createImageURLFromSimbolo(data)
    setEsconder3("mostrar")
    setMostrar3("esconder")
    setUrlImage3(url)
    setUrls({ img1: urls.img1, img2: urls.img2, img3: data })
  }

  function cancel1() {
    setLista1(false);
  }

  function cancel2() {
    setLista2(false);
  }

  function cancel3() {
    setLista3(false);
  }

  function hiddeElement1() {
    setLista1(false);
    setEsconder1("esconder");
    setMostrar1("mostrar");
    setUrls({ img1: null, img2: urls.img2, img3: urls.img3 })
  }

  function hiddeElement2() {
    setLista2(false);
    setEsconder2("esconder");
    setMostrar2("mostrar");
    setUrls({ img1: urls.img1, img2: null, img3: urls.img3 })
  }

  function hiddeElement3() {
    setLista3(false);
    setEsconder3("esconder");
    setMostrar3("mostrar");
    setUrls({ img1: urls.img1, img2: urls.img2, img3: null })
  }

  function handleFormatoPDFClick(formato, e) {
    e.preventDefault();
    setFormatoPDF(formato);
    if (formato == "a4") {
      setFormatoSeleccionado_a4("formato_seleccionado")
      setFormatoSeleccionado_a5("")
    }
    if (formato == "a5") {
      setFormatoSeleccionado_a4("")
      setFormatoSeleccionado_a5("formato_seleccionado")
    }
  }

  function handleButtonSubmit(e) {
    logger.info("Empezamos proceso de generacion");
    e.preventDefault()
    const obs = JSON.stringify({
      codigo_ler: codigoList[codigo_ler],
      fecha_envasado: fecha_envasado,
      observaciones,
      email,
      direccion,
      formatoPDF,
      peligroso: esProductoPeligroso
    });
    const simbolos = obj2Array(urls);
    let smb = getValArrOfObj(simbolos, 'id');
    if (!esProductoPeligroso) {
      smb = [];
    }
    logger.info("Array de simbolos", smb);
    if (formatoPDF == 'a5') {
      logger.info("Generamos A5");
      genPortrait(
        { residuo: n_residuo, cod: codigoList[codigo_ler] , smb, date: fecha_envasado, obs, observaciones },
        { empresa, nima, mail: email, tel: telefono, dir: direccion, logo: recuperar(storageKeys.user.data).user.logotipo },
        simbolos
      );
    } else if (formatoPDF == 'a4') {
      logger.info("Generamos A4");
      genLandscape(
        { residuo: n_residuo, cod:codigoList[codigo_ler], smb, date: fecha_envasado, obs, observaciones },
        { empresa, nima, mail: email, tel: telefono, dir: direccion, logo: recuperar(storageKeys.user.data).user.logotipo },
        simbolos
      );
    }
  }

  async function getResiduos() {
    const residuos = await Residuos.list();
    if (residuos == 401) {
      navigate('/');
      return;
    } else {
      almacenar(storageKeys.residuos.data, residuos);
      const arr = (residuos);
      logger.debug('Residuos procesado', arr);
      setResiduosArrObj(arr);
      return residuos;
    }
  }

  useEffect(() => {
    handleFormatoPDFClick("a4", {preventDefault: () =>{}})
    getResiduos().then((data) => {
      setCodigoList(data.filter(r => {
        let isPeligroso = RESIDUOS_PELIGROSOS.indexOf(getCodigoLer(r.desc)) >= 0;
        logger.debug("Is in Peligrososo: ", isPeligroso, ", ", RESIDUOS_PELIGROSOS.indexOf(getCodigoLer(r.desc)));
        const code = getCodigoLer(r.desc);
        if (!esProductoPeligroso && !isPeligroso) isPeligroso = true;
        isPeligroso = isPeligroso || code.length == 2 || code.length == 4;
        logger.info("result: ", isPeligroso, " Check: ", r);
        return isPeligroso;
      }));
      setLoading(false);
    })
  }, [esProductoPeligroso])


  function selectorHandler(val){
    setEsProductoPeligroso(val);
  }

  useEffect(() => {
    const valores = recuperar(storageKeys.user.data);
    if (!valores) {
      navigate("/")
      return
    }
    setEmpresa(valores.user.empresa)
    setNima(valores.user.eni)
    setEmail(valores.user.email)
    setDireccion(valores.user.direccion)
    setTelefono(valores.user.telefono)

    getResiduos().then((data) => {
      setCodigoList(data.filter(r => {
        let isPeligroso = RESIDUOS_PELIGROSOS.indexOf(getCodigoLer(r.desc)) >= 0;
        const code = getCodigoLer(r.desc);
        if (!esProductoPeligroso && !isPeligroso) isPeligroso = true;
        isPeligroso = isPeligroso || code.length == 2 || code.length == 4;
        logger.info("result: ", isPeligroso, " Check: ", r);
        return isPeligroso;
      }));
      setLoading(false);
    })
  }, []);

  if (loading) {
    return (
      <div className=''>
        <center>
          <Spinner animation='grow' variant='success' />
        </center>
      </div>
    );
  } else {
    return (<div className='generador'>
      <PeligrosoSelector value={null} hidden={esProductoPeligroso!= null} onChange={(val)=> selectorHandler(val)}/>
       { esProductoPeligroso != null && <div className="">
        <form className='form_generador' onSubmit={handleButtonSubmit}>
          <h2>Generador de etiquetas</h2>
          <p>1. Datos de la empresa y NIMA:</p>
          <label className='label_generador' for>
            <input required
              className='inputs_generador margen_inputs_generador'
              type="text"
              name="empresa"
              placeholder='EMPRESA'
              value={empresa}
              onChange={handleEmpresaChange}
            />
            <input required
              className='inputs_generador margen_generador'
              type="text"
              name="nima"
              placeholder='NIMA'
              value={nima}
              onChange={handleNimaChange}
            />
            <input required
              className='inputs_generador margen_generador'
              type="text"
              name="email"
              placeholder='EMAIL'
              value={email}
              onChange={handleEmailChange}
            />
            <input required
              className='inputs_generador margen_generador'
              type="text"
              name="direccion"
              placeholder='DIRECCIÓN'
              value={direccion}
              onChange={handleDireccionChange} />
            <input required
              className='inputs_generador margen_generador'
              type="tel"
              name="telefono"
              placeholder='TELÉFONO'
              value={telefono}
              onChange={handleTelefonoChange}
            />
          </label>
          <p>2. Nombre del residuo:</p>
          <label className='label_generador' for>
            <input required
              className='inputs_generador'
              type="text"
              name="n_residuo"
              value={n_residuo}
              onChange={handleN_Residuo}
            />
          </label>
          
          <p>3. Selección código LER:</p>
          <label className='label_generador' for>
          <select required className='opciones_generador' name="codigo_ler" value={codigo_ler} onChange={handleCodigo_Ler}>
              <option value=""></option>
              {codigoList.map((item, i) => {
                return (<option value={i}>{item.desc}</option>)
              })}
            </select>
          </label>

          <p>4. Fecha de envasado:</p>
          <label className='label_generador' for>
            <input
              className='inputs_generador'
              type="date"
              min="1950-01-01"
              max="2050-12-31"
              name="fecha_envasado"
              value={fecha_envasado}
              onChange={handleFecha_Envasado}
            />
          </label>
          <p>5. Observaciones:</p>
          <label className='label_generador' for>
            <textarea className='inputs_generador' id="observaciones" name="observaciones" rows="5" cols="50" value={observaciones} onChange={handleObservaciones}>
            </textarea>
          </label>
          {
            esProductoPeligroso && (
              <div>
                <p className='parrafo_caracteristicas'>6. Características de peligrosidad HP y selección de Pictograma:<br />¿Cómo saber qué pictogramas elegir (HP)?<div className='simbolo_interrogacion'>?
                  <div className="help_over">
                    <img alt="" src="/img/help.jpg" />
                  </div>
                </div>
                </p>
                <div className='div_todas_etiquetas'>
                  <div className={'div_sin_etiquetas mostrar ' + mostrar1} onClick={showElement1}>
                    <p className='simbolo_mas'>+</p>
                  </div>
                  <ListaSimbolos show={lista1} index="1" onSelect={select1} onCancel={cancel1} />
                  <div className={'div_con_etiquetas ' + esconder1}>
                    <img className="imagen_etiqueta" title='Etiqueta' alt="" src={url_image1} />
                    <div className='simbolo_x' onClick={hiddeElement1}>x</div>
                  </div>
                  <div className={'div_sin_etiquetas mostrar ' + mostrar2} onClick={showElement2}>
                    <p className='simbolo_mas'>+</p>
                  </div>
                  <ListaSimbolos show={lista2} index="2" onSelect={select2} onCancel={cancel2} />
                  <div className={'div_con_etiquetas ' + esconder2} onClick={hiddeElement2}>
                    <img className="imagen_etiqueta" title='Etiqueta' alt="" src={url_image2} />
                    <div className='simbolo_x' onClick={hiddeElement2}>x</div>
                  </div>
                  <div className={'div_sin_etiquetas mostrar ' + mostrar3} onClick={showElement3}>
                    <p className='simbolo_mas'>+</p>
                  </div>
                  <ListaSimbolos show={lista3} index="3" onSelect={select3} onCancel={cancel3} />
                  <div className={'div_con_etiquetas ' + esconder3} onClick={hiddeElement3}>
                    <img className="imagen_etiqueta" title='Etiqueta' alt="" src={url_image3} />
                    <div className='simbolo_x' onClick={hiddeElement3}>x</div>
                  </div>
                </div>
                <p>7. Selecciona el tamaño y la posición de la etiqueta para imprimir:</p>
                <div className='div_todos_tamanios'>
                  <div className={'div_a4 ' + formato_seleccionado_a4} onClick={(e) => handleFormatoPDFClick("a4", e)}>
                    <div className='div_tamanio_a4'>
                      <div className='div_interno_a4' onClick={(e) => handleFormatoPDFClick("a4", e)}>
                        <span className='span_tamanio_a4'><i>A4</i></span>
                      </div>
                    </div>
                  </div>
                  <div className={'div_a5 ' + formato_seleccionado_a5} onClick={(e) => handleFormatoPDFClick("a5", e)}>
                    <div className='div_tamanio_a5'>
                      <div className='div_interno_a5' onClick={(e) => handleFormatoPDFClick("a5", e)}>
                        <span className='span_tamanio_a5'><i>A5</i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          <p className={`parrafo_generador ${esProductoPeligroso ? '' : 'sin-margin'}`}>
            {esProductoPeligroso ? '8. Genera la etiqueta en formato pdf y ya puedes imprimirla:' : '6. Genera la etiqueta en formato pdf y ya puedes imprimirla:'}
          </p>
          <button type="submit" className='btn_generador'>Generar etiqueta</button>
        </form>
      </div>}
    </div>
    )
  }

}
