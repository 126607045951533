import {  Logger } from "@kcram-solutions/logger";


const logger = new Logger('app');

/**
 * Obtiene el código del residuo
 * @param {string} residuo nombre del residuo
 */
export function getCodigoLer(residuo){
	logger.log('Entrada', residuo);
	let index = residuo.replace(/\s*/gmi, "");
	const regex = /\d*/gmi
	const result = index.matchAll(regex);
	index =result.next().value;
	logger.log("Indice", index);
	const slices = residuo.split('-');
	if(!slices.length > 1){
		return null;
	}
	const codigo = slices[0].trim();
	logger.log("Slida", codigo);
	return index[0];
}


export function createSelectForLer(items){
	const obj = {}
	items.forEach(item => {
		try{
		const code = getCodigoLer(item.desc);
		logger.debug('Código', code);
		logger.debug('length', code.length);
		if(code.length === 2){
			// grupo
			logger.debug('Encontrado Grupo', item);
			logger.debug('Codigo', code);
			obj[code] = {
				data: item,
				sub:{}
			}
		}else if(code.length === 4){
			// subgrupo
			const parent = code.substring(0, 2);
			if(obj[parent] && obj[parent].sub)
				obj[parent].sub[code] = {
					data: item,
					fields: []
			}

		}else if(code.length === 6) {
			const parent = code.substring(0, 2);
			const subparent = code.substring(0,4);
			logger.debug('Parent:', parent, ' SubParent:', subparent);
			logger.debug('Current:', obj[parent]);
			if(obj[parent] && obj[parent].sub && obj[parent].sub[subparent])
				obj[parent].sub[subparent].fields.push(item);
			else
				logger.debug('No se ha metido:', item.desc)
			}

		logger.debug('Obj:', obj);
		}catch(err){
			logger.error(err.message);
			logger.warn('No se ha metido:', item.desc, item);
		}
	});

	return obj;
}