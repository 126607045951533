import urlJoin from "url-join";
import api from "../constants/api";
import globals from "../constants/globals";
import storageKeys from "../constants/storageKeys";
import { createImageURLFromSimbolo } from "./image";
import { recuperar } from "./local_storage";
import { getCodigoLer } from "./codigos";

/**
 * Los formatos de papel que se usarán
 */
export const PAPER = {
	A4: 'a4',
	A5: 'a5'
}

/**
 * Las orientaciones admitidas
 */
export const ORIENTATION = {
	landscape: 'l',
	portrait: 'p',
}

function getLogo(logo){
	console.log(logo);
	if(logo == '/img/login128.png' || !logo){
		// no hay logo
		console.log('Logo por defecto', globals.logo.default);
		return globals.logo.default;
	}else return logo;
}

function generateTags(simbolos) {
	const etiqueta = `<div class="etiqueta">
		<img class="etiqueta" title='{code}' alt="" src="{src}" />
		<div>{code}</div>
		<div>{desc}</div>
	</div>`
	let out = "";
	simbolos.map((element, i) => {
		console.log(element)
		let tmp = etiqueta.replace('{code}', element.codigo);
		tmp = tmp.replace('{code}', element.codigo)
		tmp = tmp.replace('{desc}', element.desc);
		tmp = tmp.replace('{src}', createImageURLFromSimbolo(element));
		out += tmp;
	});
	return out;
}

function splitCodigoLer(codigo){
	try{
		const index = codigo.search(/[aA-zZ]/)
		console.log("codigo", codigo);
		console.log("indice", index);
		const split = [
			getCodigoLer(codigo),
			codigo.substring(index)];
			console.log("data", split);
		if(split.length < 2) {
			return {code : '', msg: ''}
		}
		return {
			code: split[0],
			msg: split[1]
		}
	}catch(err){
		return {code : '', msg: ''}

	}
}

export async function genLandscape(data = {residuo: '', obs: '', cod: {id: 0, desc: ''}, date: ''}, user = {empresa: ''}, simbolos = []) {
	console.log("Data input:", data, user);
	const html = `<!DOCTYPE html>
	<html lang="en">
	
	<head>
		<meta charset="UTF-8">
		<meta http-equiv="X-UA-Compatible" content="IE=edge">
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.4.1/jspdf.debug.js"
			integrity="sha384-THVO/sM0mFD9h7dfSndI6TS0PgAGavwKvB5hAxRRvc0o9cPLohB0wb/PTA7LdUHs"
			crossorigin="anonymous"></script>
	
		<!-- html2canvas -->
		<script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>
		<title></title>
		<style>
			.t {
				font-size: 14pt;
				;
			}
	
			.r {
				font-size: 20pt;
			}
	
			.space-r {
				margin-right: 5mm;
			}
			.residuo{
				font-size: 10pt;
			}
	
			.etiqueta {
				min-width: 28%;
				text-align: center;
				display: inline-block;
				font-size: 10pt;
				margin: 2mm 1mm;
			}
			.etiqueta img{
				height: 45mm;
				width: initial;
			}

			.end{
				width: 100mm;
				font-size: 11pt;
			}
	
			.inline {
				display: inline-block;
				word-wrap: break-word;
			}
	
			.rel {
				position: relative;
			}
	
			.val {
				font-size: 18pt
			}
	
			* {
				padding: 0;
				margin: 0;
				box-sizing: border-box;
			}
	
			.page {
				width: 287mm;
				height: 200mm;
				border: solid .5mm black;
			}
	
			.border-bottom {
				border: none;
				border-bottom: solid .5mm black;
			}
	
			.border-r {
				border: none;
				border-right: solid .5mm black;
			}
	
			.logo {
				height: 50mm;
				width: 30%;
				display: inline-flex;
				text-align: center;
				align-items: center;
				justify-content: center;
			}
	
			.aqua{
				height: 23mm;
				opacity: 0.4;
				rotate: 90deg;
				margin-left: 65mm;
				position: absolute;
				top: 11mm;
				display: none;
			}
			.simbolos {
				width: 60%;
				height: 69.0mm;
				text-align: center;
	
			}
	
			.detalle {
				height: 80mm;
				padding: 5mm;
			}
	
			.empresa-h {
				width: 75%;
			}
	
			.row {
				margin-bottom: 10mm;
			}
	
			.tel {
				margin-right: 20mm;
			}
	
			.obs {
				position: absolute;
				top: 1mm;
				padding-left: 3mm;
			}
	
			.data {
				padding: 10pt
			}
	
			.logo img {
				max-width: 100%;
				max-height: 49mm;
			}
		</style>
	</head>
	
	<body>
		<div class="page">
			<div class="border-bottom">
				<div class="logo border-r">
					<img title='HP1' alt="" src="{logo}" />
				</div>
				<div class="inline obs data">
					<div class="row">
						<div class="inline t">código Ler:</div>
						<div class="inline val" style="margin-right: 20mm">{cod}</div>
						<div class="inline residuo" style="width: 112mm">{residuo} </div>
					</div>
					<div>
						<div class="inline t space-r">Nombre residuo:</div>
						<div class="inline val" style="max-width: 65%;">{nombre}</div>
					</div>
				</div>
			</div>
			<div class="border-bottom">
				<div class="detalle">
					<div class="">
						<div class="empresa-h inline t">Empresa</div>
						<div class="empresa-nima inline t">Nima</div>
					</div>
					<div class="row">
						<div class="empresa-h inline val">{empresa}</div>
						<div class="empresa-nima inline val">{nima}</div>
					</div>
					<div class="t">
						Dirección
					</div>
					<div class="row val">{dir}</div>
					<div class="row">
						<div class="tel inline">
							<div class="inline t space-r">Teléfono: </div>
							<div class="inline val">{tel}</div>
						</div>
						<div class="inline">
							<div class="inline t space-r">Email: </div>
							<div class="inline val">{mail}</div>
						</div>
					</div>
					<div>
						<div class="inline t space-r">Fecha envasado:</div>
						<div class="inline val">{date}</div>
					</div>
				</div>
			</div>
			<div class="rel">
				<div class="simbolos border-r inline">
					{tag}
				</div>
				<div class="inline 	obs end">
					<div class="t row">Observaciones:</div>
					<div class="">{obs}</div>
					<image src="{src.image}" class="aqua" />
				</div>
			</div>
		</div>
		<script>
		const url = '{url}';
		const data = {
			residuo_id: {ajax.residuo.id},
			nombre_residuo: '{ajax.residuo.nombre}',
			imagen: null,
			empresa: '{ajax.empresa}',
			nima: '{ajax.nima}',
			telefono: '{ajax.tel}',
			simbolos: {ajax.simbolos},
			observaciones: \`{ajax.obs}\`
		}
		const request = new XMLHttpRequest();
		const token = '{ajax.token}';
		request.open('POST', url);
		request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		request.setRequestHeader("Authorization", "Bearer " + token);
		function save() {
			let element = document.body;
			html2canvas(element,
				{
					windowWidth: element.scrollWidth,
					windowHeight: element.scrollHeight,
					onrendered: function (canvas) {

						//create image from web page
						var img = canvas.toDataURL("image/png");
						data.imagen = img;
						//create pdf object and add image to it, and then save
						var doc = new jsPDF('l', 'mm', 'a4');
						doc.addImage(img, 'JPEG', 5, 5);
						data.pdf = doc.output('dataurlstring');
						console.log(data)
						request.onerror = (data) =>{
							console.log(data)
							console.log(request.responseText)
							alert('No se ha podido guardar en el servidor');
						}
						request.onload = (obj) =>{
							console.log(obj)
							doc.output('save', '{fileName}.pdf');
							// window.close();
						}
						request.send(JSON.stringify(data));

					}
				});
			}
				save();
		</script>
	</body>
	
	</html>`;
	const w = window.open(null, "test", "menubar=yes,scrollbars=yes,status=yes,location=yes");
	let final = html.replace('{empresa}', user.empresa);
	const codeLer = splitCodigoLer(data.cod.desc);
	final = final.replace('{tag}',generateTags(simbolos) )
	final = final.replace('{nombre}', data.residuo );
	final = final.replace('{cod}', codeLer.code );
	final = final.replace('{residuo}', codeLer.msg );
	final = final.replace('{tel}', user.tel );
	final = final.replace('{dir}', user.dir );
	final = final.replace('{mail}', user.mail );
	final = final.replace('{nima}', user.nima );
	final = final.replace('{logo}', getLogo(user.logo) );
	final = final.replace('{date}', data.date );
	final = final.replace('{obs}', data.observaciones );
	final = final.replace('{ajax.obs}', data.obs );
	final = final.replace('{ajax.tel}', user.tel );
	final = final.replace('{ajax.simbolos}', JSON.stringify(data.smb));
	final = final.replace('{fileName}', data.fileName ?? 'etiqueta' );
	final = final.replace('{ajax.empresa}', user.empresa);
	final = final.replace('{ajax.nima}', user.nima );
	final = final.replace('{ajax.token}', recuperar(storageKeys.user.token) );
	final = final.replace('{ajax.residuo.id}', data.cod.id );
	final = final.replace('{ajax.residuo.nombre}', data.residuo );
	final = final.replace('{src.image}', globals.logo.default );
	final = final.replace('{url}', urlJoin(api.host, api.base, api.etiquetas) );
	w.document.write(final);
}

export function genPortrait(data = {residuo: '', obs: '', cod: {id: 0, desc: ''}, date: ''}, user = {empresa: ''}, simbolos = []) {
	console.log("Data input:", data, user);
	const html = `
	<!DOCTYPE html>
<html lang="en">

<head>
	<meta charset="UTF-8">
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.4.1/jspdf.debug.js"
		integrity="sha384-THVO/sM0mFD9h7dfSndI6TS0PgAGavwKvB5hAxRRvc0o9cPLohB0wb/PTA7LdUHs"
		crossorigin="anonymous"></script>

	<!-- html2canvas -->
	<script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>
	<title></title>
	<style>
		.t {
			font-size: 10pt;
			
		}
		.residuo{
			font-size: 8pt;
		}
		.r {
			font-size: 18pt;
		}

		.space-r {
			margin-right: 5mm;
		}

		.etiqueta {
			min-width: 28%;
			text-align: center;
			display: inline-block;
				font-size: 8pt;
				margin: 2mm 1mm;
		}

		.inline {
			display: inline-block;
			word-wrap: break-word;
		}

		.rel {
			position: relative;
		}

		.end{
			width: 55mm;
			font-size: 10pt;
		}

		.etiqueta img {
			height: 35mm;
			width: initial;
		}

		.val {
			font-size: 16pt
		}

		* {
			padding: 0;
			margin: 0;
			box-sizing: border-box;
		}

		.page {
			width: 200mm;
			height: 143.5mm;
			border: solid .5mm black;
		}

		.border-bottom {
			border: none;
			border-bottom: solid .5mm black;
		}

		.border-r {
			border: none;
			border-right: solid .5mm black;
		}

		.logo {
			height: 35mm;
			width: 30%;
			display: inline-flex;
			text-align: center;
			align-items: center;
			justify-content: center;
		}

		.simbolos {
			width: 60%;
			height: 50mm;
			text-align: center;

		}
	
		.aqua{
			height: 18mm;
			rotate: 90deg;
			margin-left: 45mm;
			position: absolute;
			top: 8mm;
			opacity: 0.4;
			display:none;
		}

		.detalle {
			height: 58mm;
			padding: 5mm;
		}

		.empresa-h {
			width: 75%;
		}

		.row {
			margin-bottom: 10pt;
		}

		.tel {
			margin-right: 20mm;
		}

		.obs {
			position: absolute;
			top: 1mm;
			padding-left: 3mm;
		}

		.data {
			padding: 10pt
		}

		.logo img {
			max-width: 100%;
			max-height: 34mm;
		}
	</style>
</head>

<body>
	<div class="page">
		<div class="border-bottom">
			<div class="logo border-r">
				<img title='HP1' alt="" src="{logo}" />
			</div>
			<div class="inline obs data">
				<div class="row">
					<div class="inline t">código Ler:</div>
					<div class="inline val" style="margin-right: 20mm">{cod}</div>
					<div class="inline residuo" style="width: 50mm">{residuo} </div>
				</div>
				<div>
					<div class="inline t space-r">Nombre residuo:</div>
					<div class="inline val" style="max-width: 106mm;">{nombre}</div>
				</div>
			</div>
		</div>
		<div class="border-bottom">
			<div class="detalle">
				<div class="">
					<div class="empresa-h inline t">Empresa</div>
					<div class="empresa-nima inline t">Nima</div>
				</div>
				<div class="row">
					<div class="empresa-h inline val">{empresa}</div>
					<div class="empresa-nima inline val">{nima}</div>
				</div>
				<div class="t">
					Dirección
				</div>
				<div class="row val">{dir}</div>
				<div class="row">
					<div class="tel inline">
						<div class="inline t space-r">Teléfono: </div>
						<div class="inline val">{tel}</div>
					</div>
					<div class="inline">
						<div class="inline t space-r">Email: </div>
						<div class="inline val">{mail}</div>
					</div>
				</div>
				<div>
					<div class="inline t space-r">Fecha envasado:</div>
					<div class="inline val">{date}</div>
				</div>
			</div>
		</div>
		<div class="rel">
			<div class="simbolos border-r inline">
				{tag}
			</div>
			<div class="inline 	obs end">
				<div class="t row">Observaciones:</div>
				<div class="">{obs}</div>
				<image src="{src.image}" class="aqua" />
			</div>
		</div>
	</div>
	<script>
		const url = '{url}';
		const data = {
			residuo_id: {ajax.residuo.id},
			nombre_residuo: '{ajax.residuo.nombre}',
			imagen: null,
			empresa: '{ajax.empresa}',
			nima: '{ajax.nima}',
			telefono: '{ajax.tel}',
			simbolos: {ajax.simbolos},
			observaciones: \`{ajax.obs}\`
		}
		const request = new XMLHttpRequest();
		const token = '{ajax.token}';
		request.open('POST', url);
		request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		request.setRequestHeader("Authorization", "Bearer " + token);
		function save() {
			let element = document.body;
			html2canvas(element,
				{
					windowWidth: element.scrollWidth,
					windowHeight: element.scrollHeight,
					onrendered: function (canvas) {

						//create image from web page
						var img = canvas.toDataURL("image/png");
						data.imagen = img;
						//create pdf object and add image to it, and then save
						var doc = new jsPDF('p', 'mm', 'a4');
						doc.addImage(img, 'JPEG', 5, 5);
						data.pdf = doc.output('dataurlstring');
						console.log(data)
						request.onerror = (data) =>{
							console.log(data)
							console.log(request.responseText)
							alert('No se ha podido guardar en el servidor');
						}
						request.onload = (obj) =>{
							console.log(obj)
							doc.output('save', '{fileName}.pdf');
							// window.close();
						}
						request.send(JSON.stringify(data));

					}
				});
			}
				save();
	</script>
</body>

</html>`
	const w = window.open(null, "portrait", "menubar=yes,scrollbars=yes,status=yes,location=yes");
	let final = html.replace('{empresa}', user.empresa);
	const codeLer = splitCodigoLer(data.cod.desc);
	final = final.replace('{tag}',generateTags(simbolos) )
	final = final.replace('{nombre}', data.residuo );
	final = final.replace('{cod}', codeLer.code );
	final = final.replace('{residuo}', codeLer.msg );
	final = final.replace('{tel}', user.tel );
	final = final.replace('{dir}', user.dir );
	final = final.replace('{mail}', user.mail );
	final = final.replace('{nima}', user.nima );
	final = final.replace('{logo}', getLogo(user.logo) );
	final = final.replace('{date}', data.date );
	final = final.replace('{obs}', data.observaciones );
	final = final.replace('{ajax.obs}', data.obs );
	final = final.replace('{ajax.tel}', user.tel );
	final = final.replace('{ajax.simbolos}', JSON.stringify(data.smb));
	final = final.replace('{fileName}', data.fileName ?? 'etiqueta' );
	final = final.replace('{ajax.empresa}', user.empresa);
	final = final.replace('{ajax.nima}', user.nima );
	final = final.replace('{ajax.token}', recuperar(storageKeys.user.token) );
	final = final.replace('{ajax.residuo.id}', data.cod.id );
	final = final.replace('{ajax.residuo.nombre}', data.residuo );
	final = final.replace('{src.image}', globals.logo.default );
	final = final.replace('{url}', urlJoin(api.host, api.base, api.etiquetas) );
	w.document.write(final);

}