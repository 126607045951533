import './ElementoFooter.css';

export default function ElementoFooter(){
	return (
		<div>
			<footer className="o-footer">
  				<div className="c-footer-phrase">
    				<div className="container">
      					<div className="block block-text b-text">
							<p><strong>GRUPO Servicios Ecológicos de Navarra.</strong> Grupo lider en la gestión ambiental y de residuos</p>
						</div>    
					</div>
  				</div> 
				<div className="c-footer-addresses">
					<div className="container">
						<div className="b-offices">
							<div className="b-offices__content">
								<div className="b-offices__content--logo">
									<img src="https://serviciosecologicos.com/files/2017/05/logo-sen-2.svg" alt="Logo de SEN Pamplona (central)"/>
								</div>
								<div className="b-offices__content--title">SEN Pamplona (central)</div>
								<div className="b-offices__content--text">
									<p>Polígono Los Agustinos<br />31013 Pamplona - España</p>
								</div>
								<div className="b-offices__content--location"><a href="https://serviciosecologicos.com/es/contacto">{'>>'} Localización</a></div>
								<div className="b-offices__content--text">
									<a href="tel://948 309 033">T. 948 309 033</a>
									<span>F. 948 309 182</span>
								</div>  
								<div className="b-offices__content--email">
      								<a href="mailto:info@serviciosecologicos.com">info@serviciosecologicos.com</a>
    							</div>      
								<div className="b-offices__content--email">
									<a target="_blank" href="https://www.google.com/maps/dir//42.83,-1.68841/@42.83,-1.68841,12z?hl=es" rel="noreferrer">
										Cómo llegar
									</a>
								</div>      
  							</div>
							  <div className="b-offices__content">
								<div className="b-offices__content--logo">
									<img src="https://serviciosecologicos.com/files/2017/05/logo-sen-3.svg" alt="Logo de SEN Cintruénigo"/>
								</div>
								<div className="b-offices__content--title">SEN Barcelona</div>
								<div className="b-offices__content--text">
									<p>Poligono Industrial, Calle metal, Camí de Can Sunyer, 1
									<br />08740 Sant Andreu de la Barca,<br /> Barcelona</p>
								</div>
								<div className="b-offices__content--location">
									<a href="https://serviciosecologicos.com/es/contacto">{'>>'} Localización</a>
								</div>
								<div className="b-offices__content--text">
									<a href="tel://936 530 962">T. 936 530 962</a>
								</div>  
								<div className="b-offices__content--email">
									<a href="mailto:senbarcelona@serviciosecologicos.com">senbarcelona@serviciosecologicos.com</a>
								</div>      
								<div className="b-offices__content--email">
									<a target="_blank" href="https://www.google.com/maps/dir//42.068,-1.79993/@42.068,-1.79993,12z?hl=es" rel="noreferrer">
										Cómo llegar
									</a>
								</div>      
  							</div>
  							<div className="b-offices__content">
								<div className="b-offices__content--logo">
									<img src="https://serviciosecologicos.com/files/2017/05/logo-sen-3.svg" alt="Logo de SEN Cintruénigo"/>
								</div>
								<div className="b-offices__content--title">SEN Cintruénigo</div>
								<div className="b-offices__content--text">
									<p>Polígono Cintruénigo, Calle I, Parcela 20<br />31592 Cintruénigo - España</p>
								</div>
								<div className="b-offices__content--location">
									<a href="https://serviciosecologicos.com/es/contacto">{'>>'} Localización</a>
								</div>
								<div className="b-offices__content--text">
									<a href="tel://948 815 003">T. 948 815 003</a>
									<span>F. 948 815 003</span>
								</div>  
								<div className="b-offices__content--email">
									<a href="mailto:cintruenigo@serviciosecologicos.com">cintruenigo@serviciosecologicos.com</a>
								</div>      
								<div className="b-offices__content--email">
									<a target="_blank" href="https://www.google.com/maps/dir//41.4604,1.94962/@41.4604,1.94962,12z?hl=es" rel="noreferrer">
										Cómo llegar
									</a>
								</div>      
  							</div>
							<div className="b-offices__content">
								<div className="b-offices__content--logo">
									<img src="https://serviciosecologicos.com/files/2024/02/logo-renor-2.svg" alt="Logo de RENOR Reciclajes Industriales"/>
								</div>
								<div className="b-offices__content--title">RENOR Reciclajes Industriales</div>
								<div className="b-offices__content--text">
									<p>Paseo del Prado, 12<br />01320 Oyon - Álava<br />
										<a href="https://reciclajesrenor.com" target="_blank" rel="noreferrer"><strong>www.reciclajesrenor.com</strong></a>
									</p>
								</div>
								<div className="b-offices__content--location"><a href="https://serviciosecologicos.com/es/contacto">{'>>'} Localización</a></div>
								<div className="b-offices__content--text">
									<a href="tel://945 622 100">T. 945 622 100</a>
									<span>F. 945 622 682</span>
								</div>  
								<div className="b-offices__content--email">
									<a href="mailto:info@reciclajesrenor.com">info@reciclajesrenor.com</a>
								</div>      
								<div className="b-offices__content--email">
									<a target="_blank" href="https://www.google.com/maps/dir//42.515,-2.45053/@42.515,-2.45053,12z?hl=es" rel="noreferrer">
										Cómo llegar
									</a>
								</div>      
  							</div>
							
      					</div>
						<div className="c-footer-addresses__logos">
							<div className="c-footer-addresses__logos--social">
								<ul></ul>
							</div>
							<div className="c-footer-addresses__logos--access"></div>
        					<div className="c-footer-addresses__logos--certificates">
                      			<a href="https://serviciosecologicos.com/files/2018/12/POLITICA_SEN.pdf" target="_blank" rel="noreferrer">
									<img src="https://serviciosecologicos.com/files/2018/12/politica calidad.jpg" alt="Política de Calidad y Ambiental"/>
								</a>
								<a href="https://serviciosecologicos.com/files/2023/02/iso-9001-hasta-12032025-1.pdf" target="_blank" rel="noreferrer">
									<img src="https://serviciosecologicos.com/files/2019/10/ISO 9001.jpg" alt="ISO 9001:2015"/>
								</a>
								<a href="https://serviciosecologicos.com/files/2023/02/iso-14001-hasta-12032025-1.pdf" target="_blank" rel="noreferrer">
									<img src="https://serviciosecologicos.com/files/2019/10/ISO 14001.jpg" alt="ISO 14001:2015"/>
								</a>
								<a href="" target="_blank" rel="noreferrer">
									<img src="https://serviciosecologicos.com/files/2022/11/logo-programa-onnovarse-navarra-1.jpg" alt="Innovarse"/>
								</a>
								<a href="" target="_blank" rel="noreferrer">
									<img src="https://serviciosecologicos.com/files/2023/02/rcc-ministerio.jpg" alt="RCC España"/>
								</a>
								<a href="" target="_blank" rel="noreferrer">
									<img src="https://serviciosecologicos.com/files/2023/05/logo-pin-b-cast-eusk-1.jpg" alt="RCC España"/>
								</a>
                  			</div>
      					</div>
      					<div className="c-footer-addresses__copyright">
        					© Servicios Ecológicos de Navarra 2023
							<ul>
								<li className=" first cname-privacy">
									<a href="https://serviciosecologicos.com/es/aviso-legal" className="navslug-aviso-legal">Aviso legal</a>
								</li>
								<li className=" last cname-cookies">
									<a href="https://serviciosecologicos.com/es/politica-de-cookies" className="navslug-politica-de-cookies">Política de cookies</a>
								</li>
							</ul>      
						</div>
					</div>
				</div>
  				<div className="c-footer-bottom">
    				<div className="container">
      					<div className="c-footer-bottom__content">
        					<div>		
							 
          						<div className="block block-text b-text">
									<p>
										GESTIÓN DE RESIDUOS: planta de recuperación, valorización, transferencia autorizada, tratamiento y gestión de residuos, metales, chatarra, residuos especiales, residuos tóxicos, residuos peligrosos, papel y cartón, plástico, basura industrial, madera, servicio de contenedores.
									</p>
								</div>        
							</div>
							<div>
								<div className="block block-text b-text">
									<p>
										SERVICIOS: recepción, almacenaje, transformación y carga de materiales y residuos, transporte de residos y reciclado, transporte de residuos en Pamplona, Cintruénigo, Navarra, Oyón, Álava, Siguenza, Guadalajara, reciclaje, compostaje, contenedores, recuperaciones férricas.
									</p>
								</div>        
							</div>
							<div>
								<div className="block block-text b-text">
									<p>Consultoría medioambiental, solución óptima y eficiente</p>
									<p>Destrución documental, emisión de certificado, garantía</p>
									<p>Centros Autorizados para el Tratamiento de residuos industriales</p>
								</div>        
							</div>
        					<div>
          						<div className="block block-text b-text">
									<p>
										<b>
											"Esta empresa ha recibido una ayuda del Gobierno de Navarra en virtud de la convocatoria de 2021 de "Fomento de la Empresa Digital de Navarra"
										</b>
									</p>
									<div>
										<div className="gmail_signature" data-smartmail="gmail_signature" dir="ltr" Style="caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); font-family: Helvetica; font-size: 12px; font-Style: normal; font-variant-caps: normal; font-weight: normal; letter-spacing: normal; orphans: auto; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: auto; word-spacing: 0px; -webkit-text-size-adjust: auto; -webkit-text-stroke-width: 0px; text-decoration: none;">&nbsp;</div>
									</div>
									<p>
										<strong><a href="https://serviciosecologicos.com/es/contacto" target="_self">CONTACTO</a></strong>
									</p>
								</div>        
							</div>
						</div>
					</div>
				</div>

			</footer>
			<a href id="to-top" data-rel="body" className="scroll-to scroll-up"><i className="fa fa-angle-double-up"></i></a>  
		</div>
	)
}
