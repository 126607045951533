import React, { useState } from 'react';   
import './ResetearPass.css';   
import literals from '../../constants/literals';
import { useNavigate, useParams } from 'react-router-dom';
import { Login } from '../../requests/login';

export default function ResetearPassForm() { 
  const {token, sha} = useParams();
  const navigate = useNavigate();
  const placeholder_nueva_pass = literals.resetear_pass.nueva_pass;
  const placeholder_repetir_nueva_pass = literals.resetear_pass.repetir_nueva_pass;
 
  const [nueva_pass, setNuevaPass] = useState('');
  const [repetir_nueva_pass, setRepetirNuevaPass] = useState('');  
  const [errorMessage, setErrorMessage] = useState('Las contraseñas no coinciden');

  const div_esconder_error_resetear = "div_esconder_error_resetear";
  const div_mostrar_error_resetear = "div_mostrar_error_resetear";
  const [div_error_resetear, setDivErrorResetear] = useState(div_esconder_error_resetear);

  function handleNuevaPassChange(e) {
    const { name, value } = e.target
    setNuevaPass(value)
    setDivErrorResetear(div_esconder_error_resetear)
  }

  function handleRepetirNuevaPassChange(e) {
    const { name, value } = e.target
    setRepetirNuevaPass(value) 
    setDivErrorResetear(div_esconder_error_resetear)
  } 

  function handleButtonSubmit(e) {
    e.preventDefault();
    if(nueva_pass !== repetir_nueva_pass) {
      setErrorMessage(errorMessage);
      setDivErrorResetear(div_mostrar_error_resetear) 
      setNuevaPass('')
      setRepetirNuevaPass('');
      return;
    }
    Login.setNewPass(token, sha, nueva_pass, repetir_nueva_pass).then((data)=>{
      if(!data.success){
        setErrorMessage(data.message);
        setDivErrorResetear(div_mostrar_error_resetear);
      }else{
        navigate('/');
      }
    })
  } 
  
  return (
    <div>
      <div className='formulario_resetear'>
        <h1 className='titulo_resetear'>Resetea tu contraseña</h1>
        <p className='p_resetear'>Introduce la nueva contraseña dos veces para poder cambiarla por tu antigua contraseña</p>
        <img className="imagen_resetear" title='Cambiar contraseña' alt="" src="/img/cerrar_con_llave_128.png"/> 
        <form onSubmit={handleButtonSubmit}>  
          <label className="display_inputs_resetear" for> 
            <input
              className='inputs_resetear'
              type="password"
              name="password" 
              placeholder={placeholder_nueva_pass}
              required
              value={nueva_pass}
              onChange={handleNuevaPassChange}
            />
          </label> 
          <label className="display_inputs_resetear" for> 
            <input
              className='inputs_resetear'
              type="password"
              name="repetir_password" 
              placeholder={placeholder_repetir_nueva_pass}
              required
              value={repetir_nueva_pass}
              onChange={handleRepetirNuevaPassChange}
            />
          </label>
          <div className= {div_error_resetear}> 
            <p className="parrafo_error_resetear">{errorMessage}</p> 
          </div>
          <button type="submit" className='btn_form_resetear'>Cambiar</button>
        </form> 
      </div>
    </div> 
  )
}

