import { Logger } from '@kcram-solutions/logger';
import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Login } from '../../requests/login';
import './PassRestore.css';
import { obj2Array } from '../../helpers/objects';

export default function PassRestore(props, content){
	const [email, setEmail] = useState('');
	const navigate = useNavigate();
	const [msg, setMsg] = useState('');
	const logger = new Logger('reset-pass');

	function send(){
		if(!email){
			logger.warn('No hay email', email);
			setMsg('No se ha introducido un email')
		}
		Login.resetPass(email).then(data=>{
			logger.debug('Datos', data);
			setMsg(data.message);
			if(data.success){
				setTimeout(()=>navigate('/'), 3000)
			}else{
				setMsg(obj2Array(data.errors).join('<br>'));
			}
			
		})
	}
	if(!msg)
	return (<div className='wrapper'>
		<div className="mt-3">
			<h2>Recupera la contraseña</h2>
			<p className='small'>Te enviaresmos un correo con los datos para que restablezcas tu contraseña.</p>
		</div>
		<Form.Label className='mt-5' >Email</Form.Label>
		<InputGroup>
		<InputGroup.Text id="btnGroupAddon">@</InputGroup.Text>
		<Form.Control type="email" value={email} onChange={item => {setEmail(item.target.value); logger.debug('Data:', item.target.value)}} />
		</InputGroup>
		<Button className='mt-3' variant='success' onClick={send} > Enviar <i className='fa fa-paper-plane'></i> </Button>
	</div>)
	else{
		return (<div className='wrapper'>
		<div className="mt-3">
			<h2>Recupera la contraseña</h2>
			<p className='small'>Te enviaresmos un correo con los datos para que restablezcas tu contraseña.</p>
		</div>
		<Form.Label className='mt-5' >Email</Form.Label>
		<InputGroup>
		<InputGroup.Text id="btnGroupAddon">@</InputGroup.Text>
		<Form.Control type="email" value={email} onChange={item => {setEmail(item.target.value); logger.debug('Data:', item.target.value)}} />
		</InputGroup>
		<Button className='mt-3' variant='success' onClick={send} > Enviar <i className='fa fa-paper-plane'></i> </Button>
		<p>{msg}</p>
	</div>)
	}
}
