export default
{
    host :'https://serviciosecologicos.com',
    base: "/api",
    etiquetas : "/etiquetas",
    users: "/users",
    login: "/users/login",
    reset: "/users/forgot",
    residuos: "/residuos",
    simbolos: "/simbolos",
    userAgent: 'KCS-Client V1.0.0',
    changePass:"/users/recovery/",
    etiqueta:{
        pdf: "/pdf",
        imagen: "/imagen"
    }
}
