import { recuperar } from "./local_storage";
import storage from "../constants/storageKeys"

export function logoutFromLocal(){
	localStorage.clear();
	sessionStorage.clear();
}

export function checkLastLogin(){
	const lastDate = recuperar(storage.user.loginDate);
	if(!lastDate) return false;
	try{
		const date = new Date(lastDate);
		return (new Date().getTime - date.getTime() >= 24 * 3600000);
	}catch(e){
		console.warn(e);
		return false;
	}
}