import React, { useState } from 'react';
import './Registro.css';
import { Registro } from '../../requests/registro'; 
import { useNavigate } from 'react-router-dom'; 
import { obj2Array } from '../../helpers/objects';

export default function RegistroForm() {
  
  const placeholder_empresa = ""
  const placeholder_email = "";
  const placeholder_direccion ="";
  const placeholder_nima ="";
  const placeholder_telefono = "";
  const placeholder_password = "";
  const placeholder_c_password = "";

  const [empresa, setEmpresa] = useState(''); 
  const [email, setEmail] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState(''); 
  const [password, setPassword] = useState('');
  const [c_password, setC_Password] = useState('');
  const [nima, setNima] = useState('');

  const div_esconder_error_registro = "div_esconder_error_registro";
  const div_mostrar_error_registro = "div_mostrar_error_registro";
  const [div_error_registro, setDivErrorRegistro] = useState(div_esconder_error_registro);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate()

  function handleEmpresaChange(e) {
    const { name, value } = e.target
    setEmpresa(value)
  }

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handleDireccionChange(e) {
    const { name, value } = e.target
    setDireccion(value)
  }

  function handleTelefonoChange(e) {
    const { name, value } = e.target
    setTelefono(value)  
  }

  function handlePasswordChange(e) {
    const { name, value } = e.target 
    setPassword(value)
  }

  function handleC_PasswordChange(e) {
    const { name, value } = e.target
    setC_Password(value)
  }

  function handleNimaChange(e) {
    const { value } = e.target
    setNima(value);
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    Registro.registro( email, password, c_password, empresa, direccion, telefono, nima).then (
      (data) => {
        if(data.success){
          console.log(data)
          navigate("/");
        }else{
          if(data.errors){
            const errs = obj2Array(data.errors);
            setErrorMessage(errs.map(i => {
              return (<li>{i}</li>)
            }));
          }else{
            data.message && setErrorMessage(data.message);
          }
          setDivErrorRegistro(div_mostrar_error_registro) 
        }
      }
    )
  }

  return (
    <div>  
      <form className="form_registro" onSubmit={handleButtonSubmit}>
      <h1 className='text-center mb-5'>Regístrate</h1>
        <label className='label_registro' for>
          EMPRESA:
          <input 
            className='sin_borde_registro'
            type="text"
            name="empresa" 
            placeholder= {placeholder_empresa}
            required
            value={empresa}
            onChange={handleEmpresaChange}
          />
        </label>
        <label className='label_registro' for> 
          EMAIL:
          <input
            className='sin_borde_registro'
            type="email"
            name="email" 
            placeholder={placeholder_email}
            required
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label className='label_registro' for>
          DIRECCIÓN:
          <input
            className='sin_borde_registro'
            type="text"
            name="direccion" 
            placeholder= {placeholder_direccion}
            required
            value={direccion}
            onChange={handleDireccionChange}
          />
        </label>
        <label className='label_registro' for>
          TELÉFONO:
          <input
            className='sin_borde_registro'
            type="tel"
            name="telefono" 
            placeholder= {placeholder_telefono}
            required
            pattern="[0-9]{9}"
            onChange={handleTelefonoChange}
          /></label>
          <label className='label_registro' for>
          NIMA:
          <input
            className='sin_borde_registro'
            type="text"
            name="nima" 
            placeholder={placeholder_nima}
            required 
            value={nima}
            onChange={handleNimaChange}
          />
        </label>
        <label className='label_registro margen_registro' for> 
          CONTRASEÑA:
          <input
            className='sin_borde_registro'
            type="password"
            name="password" 
            placeholder= {placeholder_password}
            required
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <label className='label_registro' for> 
          CONFIRMAR CONTRASEÑA:
          <input
            className='sin_borde_registro'
            type="password"
            name={placeholder_c_password}
            placeholder=''
            required
            value={c_password}
            onChange={handleC_PasswordChange}
          />
        </label> 
        <label className='checkbox_form_registro' for> 
          <input type="checkbox" id="acepto" name="acepto" required value="acepto"/> 
          <label for="acepto">Acepto las <a href=' https://www.serviciosecologicos.com/es/politica-de-privacidad'> política de privacidad</a></label><br></br>
        </label>
        <div className= {div_error_registro}> 
          <ul className="parrafo_error_registro">{errorMessage}</ul> 
        </div>
        <button className='btn_form_registro' type="submit">REGISTRARME</button>  
      </form> 
    </div>
  )
}

