import { useEffect, useState } from 'react';
import './PeligrosoSelector.css';

export default function PeligrosoSelector({value, onChange , hidden}, content){
	const [peligroso, setPeligroso] = useState(value);
	const peligrosoClass = useState("");
	const noPeligrosoClass = useState("");
	const changeFunction = (val) => {
		if( typeof onChange == "function"){
			onChange(val);
		}
		if(val){
			peligrosoClass[1]("active");
			noPeligrosoClass[1]("");
		}else if(val == false) {
			peligrosoClass[1]("");
			noPeligrosoClass[1]("active");
		}
	}

	useEffect(()=>{
		setPeligroso(value);
		changeFunction(peligroso);
	}, [value])

	return (
		<div className='peligrosoSelector-wrapper' hidden={hidden}>
			<div className="selector">
			<div className={'peligroso ' + peligrosoClass[0]} onClick={()=> changeFunction(true)}>
				Residuo Peligroso
			</div>
			<div className={'no-peligroso ' + noPeligrosoClass[0]} onClick={()=> changeFunction(false)}>
				Residuo No Peligroso
			</div>
			</div>
		</div>
	)
}
