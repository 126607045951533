export const RESIDUOS_PELIGROSOS =[
    "010304",
    "010305",
    "010307",
    "010407",
    "010505",
    "010506",
    "020108",
    "030104",
    "030201",
    "030202",
    "030203",
    "030204",
    "030205",
    "040103",
    "040214",
    "040216",
    "040219",
    "050102",
    "050103",
    "050104",
    "050105",
    "050106",
    "050107",
    "050108",
    "050109",
    "050111",
    "050112",
    "050115",
    "050601",
    "050603",
    "050701",
    "060101",
    "060102",
    "060103",
    "060104",
    "060105",
    "060106",
    "060201",
    "060203",
    "060204",
    "060205",
    "060311",
    "060313",
    "060315",
    "060403",
    "060404",
    "060405",
    "060502",
    "060602",
    "060701",
    "060702",
    "060703",
    "060704",
    "060802",
    "060903",
    "061002",
    "061301",
    "061302",
    "061304",
    "061305",
    "070101",
    "070103",
    "070104",
    "070107",
    "070108",
    "070109",
    "070110",
    "070111",
    "070201",
    "070203",
    "070204",
    "070207",
    "070208",
    "070209",
    "070210",
    "070211",
    "070214",
    "070216",
    "070301",
    "070303",
    "070304",
    "070307",
    "070308",
    "070309",
    "070310",
    "070311",
    "070401",
    "070403",
    "070404",
    "070407",
    "070408",
    "070409",
    "070410",
    "070411",
    "070413",
    "070501",
    "070503",
    "070504",
    "070507",
    "070508",
    "070509",
    "070510",
    "070511",
    "070513",
    "070601",
    "070603",
    "070604",
    "070607",
    "070608",
    "070609",
    "070610",
    "070611",
    "070701",
    "070703",
    "070704",
    "070707",
    "070708",
    "070709",
    "070710",
    "070711",
    "080111",
    "080113",
    "080115",
    "080117",
    "080119",
    "080121",
    "080312",
    "080314",
    "080316",
    "080317",
    "080319",
    "080409",
    "080411",
    "080413",
    "080415",
    "080417",
    "080501",
    "090101",
    "090102",
    "090103",
    "090104",
    "090105",
    "090106",
    "090111",
    "090113",
    "100104",
    "100109",
    "100113",
    "100114",
    "100116",
    "100118",
    "100120",
    "100122",
    "100207",
    "100211",
    "100213",
    "100304",
    "100308",
    "100309",
    "100315",
    "100317",
    "100319",
    "100321",
    "100323",
    "100325",
    "100327",
    "100329",
    "100401",
    "100402",
    "100403",
    "100404",
    "100405",
    "100406",
    "100407",
    "100409",
    "100503",
    "100505",
    "100506",
    "100508",
    "100510",
    "100603",
    "100606",
    "100607",
    "100609",
    "100707",
    "100808",
    "100810",
    "100812",
    "100815",
    "100817",
    "100819",
    "100905",
    "100907",
    "100909",
    "100911",
    "100913",
    "100915",
    "101005",
    "101007",
    "101009",
    "101011",
    "101013",
    "101015",
    "101109",
    "101111",
    "101113",
    "101115",
    "101117",
    "101119",
    "101209",
    "101211",
    "101309",
    "101312",
    "101401",
    "110105",
    "110106",
    "110107",
    "110108",
    "110109",
    "110111",
    "110113",
    "110115",
    "110116",
    "110198",
    "110202",
    "110205",
    "110207",
    "110301",
    "110302",
    "110503",
    "110504",
    "120106",
    "120107",
    "120108",
    "120109",
    "120110",
    "120112",
    "120114",
    "120116",
    "120118",
    "120119",
    "120120",
    "120301",
    "120302",
    "130101",
    "130104",
    "130105",
    "130109",
    "130110",
    "130111",
    "130112",
    "130113",
    "130204",
    "130205",
    "130206",
    "130207",
    "130208",
    "130301",
    "130306",
    "130307",
    "130308",
    "130309",
    "130310",
    "130401",
    "130402",
    "130403",
    "130501",
    "130502",
    "130503",
    "130506",
    "130507",
    "130508",
    "130701",
    "130702",
    "130703",
    "130801",
    "130802",
    "130899",
    "140601",
    "140602",
    "140603",
    "140604",
    "140605",
    "150110",
    "150111",
    "150202",
    "160104",
    "160107",
    "160108",
    "160109",
    "160110",
    "160111",
    "160113",
    "160114",
    "160121",
    "160209",
    "160210",
    "160211",
    "160212",
    "160213",
    "160215",
    "160303",
    "160305",
    "160401",
    "160402",
    "160403",
    "160504",
    "160506",
    "160507",
    "160508",
    "160601",
    "160602",
    "160603",
    "160606",
    "160708",
    "160709",
    "160802",
    "160805",
    "160806",
    "160807",
    "160901",
    "160902",
    "160903",
    "160904",
    "161001",
    "161003",
    "161101",
    "161103",
    "161105",
    "170106",
    "170204",
    "170301",
    "170303",
    "170409",
    "170410",
    "170503",
    "170505",
    "170507",
    "170601",
    "170603",
    "170605",
    "170801",
    "170901",
    "170902",
    "170903",
    "180103",
    "180106",
    "180108",
    "180110",
    "180202",
    "180205",
    "180207",
    "190105",
    "190106",
    "190107",
    "190110",
    "190111",
    "190113",
    "190115",
    "190117",
    "190204",
    "190205",
    "190207",
    "190208",
    "190209",
    "190211",
    "190304",
    "190306",
    "190402",
    "190403",
    "190702",
    "190806",
    "190807",
    "190808",
    "190810",
    "190811",
    "190813",
    "191003",
    "191005",
    "191101",
    "191102",
    "191103",
    "191104",
    "191105",
    "191107",
    "191206",
    "191211",
    "191301",
    "191303",
    "191305",
    "191307",
    "200113",
    "200114",
    "200115",
    "200117",
    "200119",
    "200121",
    "200123",
    "200126",
    "200127",
    "200129",
    "200131",
    "200133",
    "200135",
    "200137",
    ]