import React, { useEffect, useState } from 'react';
import './MisDatos.scss';
import Menu from '../Menu/Menu';
import { MisDatos } from '../../requests/misDatos';
import { almacenar, recuperar } from '../../helpers/local_storage';
import storageKeys from '../../constants/storageKeys'; 
import Toast from 'react-bootstrap/Toast';
import { obj2Array } from '../../helpers/objects';
import { useNavigate } from 'react-router-dom';
import { Logger } from '@kcram-solutions/logger';
import { checkLastLogin } from '../../helpers/logout';

const logger = new Logger('mis-datos');

export default function MisDatosForm() {

  const [empresa, setEmpresa] = useState('');
  const [nima, setNima] = useState('');
  const [email, setEmail] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [n_password, setN_Password] = useState('');
  const [c_password, setC_Password] = useState('');
  const [logotipo, setLogotipo] = useState("/img/login128.png"); 
  const [texto_imagen, setTextoImagen]= useState("SUBIR IMAGEN");
  const [show, setShow] = useState(false);
  const [toasMessage, setToastMessage] = useState('Ha habído un error');
  const navigate = useNavigate();
 
  const [esconder, setEsconder] = useState("esconder"); 

  function handleEmpresaChange(e) {
    const { name, value } = e.target
    setEmpresa(value)
  }

  function handleNimaChange(e) {
    const { name, value } = e.target
    setNima(value)
  }

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handleDireccionChange(e) {
    const { name, value } = e.target
    setDireccion(value)
  }

  function handleTelefonoChange(e) {
    const { name, value } = e.target
    setTelefono(value)
  }

  function handleN_PasswordChange(e) {
    const { name, value } = e.target
    setN_Password(value)
  }

  function handleC_PasswordChange(e) {
    const { name, value } = e.target
    setC_Password(value)
  }

  function handleImageChange(e) { 
    const file = e.target.files[0];
    const reader = new FileReader(); 
    
    reader.onloadend = () => {
      setLogotipo(reader.result);
      setTextoImagen("ACTUALIZAR IMAGEN")
      setEsconder("");
    };
    if (file) {
      reader.readAsDataURL(file);
    } 
  }

  function hiddeElement() { 
    setEsconder("esconder");
    setTextoImagen("SUBIR IMAGEN");
    setLogotipo("/img/login128.png");
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    setShow(true) 

    let logotipotmp = '/img/login128.png';
    if(logotipo !== logotipotmp){
      logotipotmp = logotipo
    }
    MisDatos.update(empresa, nima, email, direccion, telefono, n_password, c_password, logotipotmp).then (
      (data) => {
        logger.debug(data)
        almacenar(storageKeys.user.data, {user: {
          empresa, eni: nima, email, direccion, telefono, logotipo: logotipotmp
        }})
        if(data.message){
          setToastMessage(data.message);
        }
        if(data.errors){
          const erros = obj2Array(data.errors);
          let html = (<div className='error'> {data.message}:<ul className='ul_error'>
            {erros.map( item => {
              return (<li>{item}</li>)
            })}
          </ul></div>)
          setToastMessage(html);
        }
          setShow(true);
      }
    )
  }

  function recuperarDatos() {
    let valores;
    valores = recuperar(storageKeys.user.data);
    logger.debug(valores);
    setEmpresa(valores.user.empresa)
    setNima(valores.user.eni)
    setEmail(valores.user.email)
    setDireccion(valores.user.direccion)
    setTelefono(valores.user.telefono)
    let logotipotmp = valores.user.logotipo 
    if(logotipotmp && logotipotmp !== '/img/login128.png'){
      setLogotipo(logotipotmp)
      setTextoImagen("ACTUALIZAR IMAGEN")
      setEsconder("");
    }  
    
  }

  useEffect(function () {
    logger.debug(logotipo)
  }, [logotipo])

  useEffect( ()=>{
    const valores = recuperar(storageKeys.user.data); 
    if (!valores || checkLastLogin()) {
      navigate("/")
      return
    }
    recuperarDatos()
  } ,[])
  
  useEffect( () => {
    setShow(show)
  }, [show])

  function uploadClick(){
    document.getElementById('upload_input').click();
  }

  return (
    <div className='misDatos'>
      <Toast position="top-end" className='position-fixed' onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Header closeButton={false}> 
        </Toast.Header>
        <Toast.Body>{toasMessage}</Toast.Body>
      </Toast>  
      <div className='wrapper'> 
        <Menu index={1}/>
        <form className='form_mis_datos' onSubmit={handleButtonSubmit}>
          <h2>Mis Datos</h2>
          <label className='' for>
            EMPRESA:
            <input
              className='sin_borde_mis_datos'
              type="text"
              name="empresa" 
              value={empresa}
              onChange={handleEmpresaChange}
            />
          </label>
          <label className='' for>
            NIMA:
            <input
              className='sin_borde_mis_datos'
              type="text"
              name="nima" 
              value={nima}
              onChange={handleNimaChange}
            />
          </label>
          <label className='separar_mis_datos' for> 
            EMAIL:
            <input
              className='sin_borde_mis_datos'
              type="email"
              name="email" 
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <label className='separar_mis_datos' for>
            DIRECCIÓN:
            <input
              className='sin_borde_mis_datos'
              type="text"
              name="direccion" 
              value={direccion}
              onChange={handleDireccionChange}
            />
          </label>
          <label className='separar_mis_datos' for>
            TELÉFONO:
            <input
              className='sin_borde_mis_datos'
              type="tel"
              name="telefono" 
              value={telefono}
              onChange={handleTelefonoChange}
            />
          </label>
          <label className='separar_mis_datos margen_contra' for> 
            MODIFICAR CONTRASEÑA:
            <input
              className='sin_borde_mis_datos'
              type="password"
              name="n_password" 
              value={n_password}
              onChange={handleN_PasswordChange}
            />
          </label>
          <label className='separar_mis_datos' for> 
            CONFIRMAR CONTRASEÑA:
            <input
              className='sin_borde_mis_datos'
              type="password"
              name="c_password" 
              value={c_password}
              onChange={handleC_PasswordChange}
            />
          </label> 
          <input Style="display:none;" id="upload_input" type="file" onChange={handleImageChange} />
          <div className="div_subir_imagen_mis_datos">
            <img className="imagen_cargada_mis_datos" id='logo64' title='Logo' alt="" src={logotipo}/> 
            <div className={'simbolo_x ' + esconder} onClick={hiddeElement}>x</div>
            <div className='cargar_imagen_mis_datos' onClick={uploadClick}>
              <img className="subir_imagen_mis_datos" title='Iniciar sesión' alt="" src="/img/subir_imagen128.png"/>
              <label className='label_imagen_mis_datos'>{texto_imagen}
              </label> 
            </div> 
          </div>
          <button className='btn_form_mis_datos' type="submit">GUARDAR</button>  
        </form>  
      </div>
    </div>
  ) 
}

