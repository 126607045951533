import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import DisplayPanel from '../DisplayPanel/DisplayPanel';
import './PlayerBanner.css';

export default function PlayerBanner(props, content){
	const [showPlayer,setPlayer] = useState(false);
	const player = useRef(null);
	const width = window.innerWidth * .80;
	const height = (width * 9) / 16;

	function closePlayer(){
		player.current.player.player.player.stopVideo()
		setPlayer(false)
	}
	function openPlayer(){
		setPlayer(true)
	}


	return (<div>
		<DisplayPanel visible={showPlayer}>
		<div className='kcs-player-screen-overlay'></div>
		<div className="kcs-player-close" onClick={closePlayer} ><i className='fa fa-times'></i></div>
		<div className='kcs-player-screen'>
			<ReactPlayer controls ref={player} url="https://youtu.be/25SUfhuZW98" width={width} height={height} />
		</div>
		</DisplayPanel>
		<DisplayPanel visible={true}>

		<div className="kcs-player-image text-center" onClick={openPlayer}>
			<h4 className="kcs-help-title fw-bold">¿Necesitas ayuda?</h4>
			<img className='kcs-palyer-image-tag' src="/img/ayuda.png" alt="" />
			<p className=''>Accede a nuestro <br /> videotutorial</p>
		</div>
		</DisplayPanel>

	</div>)
}
