import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 
import api from "../constants/api";
import storageKeys from "../constants/storageKeys";
import { recuperar } from "../helpers/local_storage";

export class Residuos {
    static async list() {
        const url = urlJoin(api.host, api.base, api.residuos); 
        try{ 
            
            let response = await axios.get(url, { headers: {
                "User-Agent": api.userAgent,
                "Authorization": "Bearer " + recuperar(storageKeys.user.token)
            }});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            console.log('Devolvemos los datos', response.data);
            return response.data; 
             
        } catch(error){
            if(error.response && error.response.status){
                console.log(error);
                return error.response.status;
            }
            console.log('Devolvemos Mierda', error);
            return false;
        }  
    }
}